import { createClient } from '@chasi/manager/client/browser'
import { authenticate } from 'src/services/auth'
import { registerComponents } from './main'
import { exposeToMobileApp } from './terceros/mobile-app'
import { TAG_NAME } from './config'

if (import.meta.env.DEV) {
	import('./components/_manager')
}

console.info({
	date: import.meta.env.VITE_BUILD_DATE,
	rama: import.meta.env.VITE_GIT_BRANCH_NAME,
	tag: TAG_NAME
})

async function startApp() {
	exposeToMobileApp()
	await authenticate()
	await Promise.all([registerComponents(), createClient(onErrror)])

	if (!location.search.includes('terceros')) {
		import('./terceros/index')
	}
}

startApp()

function onErrror(err: unknown) {
	// esto es por que en los entornos si hay un codigo de error devuelve un html
	if (err instanceof SyntaxError && err.message.includes('is not valid JSON')) {
		location.reload()
	}
	console.error(err)
}
