<script lang="ts" context="module">
	function buildKlarnaAddress(address?: Partial<DireccionEnvio>) {
		if (!address) return
		return {
			given_name: address.nombre,
			family_name: address.apellidos,
			email: address.email,
			title: 'Mr',
			street_address: address.direccion,
			street_address2: address.direccion,
			postal_code: address.codigoPostal,
			city: address.poblacion,
			region: address.provincia,
			phone: address.telefonoContacto,
			country: 'ES'
		}
	}

	export async function autorizeKlarnaPayment(carrito: Partial<DatosCompraClient>): Promise<string> {
		await updateSessionKlarna()
		const userData = get(user) || carrito.registroUsuario!
		return new Promise((resolve, reject) => {
			const klarnaData: Record<string, any> = {
				purchase_country: 'ES',
				purchase_currency: 'EUR',
				locale: 'es-ES',
				billing_address: buildKlarnaAddress({ ...carrito.informacionFacturacion, email: userData.email })
			}
			if (!carrito.compraDigital && carrito.metodosEnvio) {
				if ('datosPuntoRecogida' in carrito.metodosEnvio[0]) {
					klarnaData.shippingAddress = buildKlarnaAddress({
						...carrito.metodosEnvio[0].datosPuntoRecogida,
						nombre: userData.nombre,
						apellidos: userData.apellidos,
						email: userData.email
					})
				} else {
					klarnaData.shippingAddress = buildKlarnaAddress({ ...carrito.informacionEnvio, email: userData.email })
				}
			}
			window.Klarna.Payments.authorize(
				{
					payment_method_category: 'klarna'
				},
				klarnaData,
				(res: any) => {
					if (res.approved) {
						resolve(res.authorization_token)
					} else {
						reject()
					}
				}
			)
		})
	}
</script>

<script lang="ts">
	import { createKlarnaSession, updateSessionKlarna } from 'src/services/checkout'
	import { user } from 'src/store/user'
	import { createEventDispatcher } from 'svelte'
	import { get } from 'svelte/store'

	const dispatch = createEventDispatcher<{ ready: string }>()

	function initializeKlarna(token: string) {
		window.Klarna.Payments.init({
			client_token: token
		})
		window.Klarna.Payments.load({
			container: '#klarna-payments-container',
			payment_method_category: 'pay_later'
		})
	}
	function loadKlarnaSDK(node: HTMLDivElement) {
		const script = document.createElement('script')
		script.setAttribute('src', 'https://x.klarnacdn.net/kp/lib/v1/api.js')
		script.setAttribute('type', 'application/javascript')
		script.onload = async () => {
			const klarnaSession = await createKlarnaSession()
			initializeKlarna(klarnaSession.clientToken)
			dispatch('ready', klarnaSession.sessionId)
		}
		node.appendChild(script)
	}
</script>

<div id="klarna-payments-container" use:loadKlarnaSDK></div>
