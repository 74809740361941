import { CNotifier } from '@chasi/ui'
import http from 'src/helpers/http'
import { createUrl, debounce, handleValidationErrors, toStringQueries } from 'src/helpers/utils'
import { Panel } from 'src/lib/panels/panel'
import Cookies from 'js-cookie'
import { datosCompra, datosCompraConfirmada } from 'src/store/checkout'
import { DEFAULT_QUERY_PARAMS } from './constants'
import { authenticate, type RegisterUserParams } from 'src/services/auth'
import { toRoute } from '@chasi/manager/client/shared'
import { get, writable } from 'svelte/store'
import type { NormalizedProduct } from './product'
import { autorizeKlarnaPayment } from 'src/lib/checkout/PaymentStep/Klarna.svelte'

export async function addProduct(product: NormalizedProduct, idPuntoRecogida?: number) {
	try {
		const url = createUrl('/carrito/anadirProducto', DEFAULT_QUERY_PARAMS)
		const params = {
			cantidad: 1,
			idProducto: product.id,
			idProductoUsado: product.idUsado,
			idPuntoRecogida
		}
		// if (product.idUsado) {
		//   params.idProductoUsado = product.idUsado
		// } else {
		//   params.idProducto = product.id
		// }
		const pedido = await http.post<ContenidoPedido | ErrorCompraV2>(url, params)
		if ('errorCode' in pedido) throw new Error(pedido.message)
		datosCompra.update((compra) => {
			if (!compra) compra = { contenidoPedido: pedido }
			else compra.contenidoPedido = pedido
			return compra
		})
		await Panel.open('PanelBasket')
	} catch (error) {
		if (error instanceof Error) return CNotifier.error(error.message)
		CNotifier.error('No se pudo añadir el producto a la compra')
	}
}

export async function addProducts(products: { cantidad: number; idProducto: number }[]) {
	try {
		const url = createUrl('/carrito/anadirProductos', DEFAULT_QUERY_PARAMS)
		const pedido = await http.post<{ contenidoPedido: ContenidoPedido } | ErrorCompraV2>(url, products)
		if ('errorCode' in pedido) throw new Error(pedido.message)
		const { contenidoPedido } = pedido
		datosCompra.update((compra) => {
			if (!compra) compra = { contenidoPedido }
			else compra.contenidoPedido = contenidoPedido
			return compra
		})
		await Panel.open('PanelBasket')
	} catch (error) {
		if (error instanceof Error) return CNotifier.error(error.message)
		CNotifier.error('No se pudo añadir los producto a la compra')
	}
}

let carrito: Partial<DatosCompraClient> = {
	desagruparPedido: true
}

export const loadingDatosCompra = writable(true)
export async function fetchCart() {
	if (Cookies.get('ccrt') || Cookies.get('ccrtv')) {
		loadingDatosCompra.set(true)
		try {
			const url = createUrl('/carrito/datosCompra', DEFAULT_QUERY_PARAMS)
			const compra = await http.post<DatosCompra>(url)
			carrito = createCartValues(compra)
			datosCompra.set(compra)
		} catch (error) {
			console.error(error)
		}
	}
	loadingDatosCompra.set(false)
}

const CART_SERVICES = {
	ACTUALIZAR_UNIDADES(cantidad: number, product: NormalizedProduct) {
		const params = {
			cantidad,
			idProducto: product.id,
			idProductoUsado: product.idUsado
		}
		carrito.adicionProductoCompra = params
		if (cantidad <= 0) return '/carrito/eliminarProductoCompra'
		return '/carrito/actualizarUnidadesCompra'
	},
	ELIMINAR_PRODUCTO(product: NormalizedProduct) {
		return this.ACTUALIZAR_UNIDADES(0, product)
	},
	REGISTRAR_SOCIO(si_no: boolean) {
		carrito.registrarSocio = si_no
		return '/carrito/regsocio'
	},
	REGISTRAR_USUARIO(userData: RegisterUserParams | undefined) {
		carrito.registroUsuario = userData
		if (carrito.registroUsuario) {
			if (carrito.rgpd) {
				carrito.rgpd.email = carrito.registroUsuario.email
			}
			if (carrito.rgpdSocio) {
				carrito.rgpdSocio.email = carrito.registroUsuario.email
			}
		}
	},
	PEDIDO_AGRUPADO(desagrupar: boolean) {
		carrito.desagruparPedido = desagrupar
		return '/carrito/actualizarMetodosEnvio'
	},
	NUEVA_DIRECCION(address: DireccionEnvioNueva) {
		carrito.informacionEnvio = address
		return '/carrito/nuevaDireccionEnvio'
	},
	NUEVA_DIRECCION_ANONIMO(address: DireccionEnvioNueva) {
		carrito.informacionEnvio = address
		return '/carrito/cambiarCodigoPostalEnvio'
	},
	CAMBIAR_DIRECCION(address: DireccionEnvio) {
		carrito.informacionEnvio = address
		return '/carrito/cambiarDireccionEnvio'
	},
	MODIFICAR_DIRECCION(address: DireccionEnvio) {
		carrito.informacionEnvio = address
		return '/carrito/modificaDireccionEnvio'
	},
	ACTUALIZAR_METODO_ENVIO(metodo: MetodosEnvioClient) {
		if (!carrito.metodosEnvio) carrito.metodosEnvio = []
		const toUpdate = carrito.metodosEnvio.findIndex((v) => v.idVendedor === metodo.idVendedor)
		carrito.metodosEnvio[toUpdate] = metodo
		return '/carrito/actualizarMetodosEnvio'
	},
	ACTUALIZAR_CONTACTO_RECOGIDA(vendedor: number, prefijo?: string, phone?: string) {
		if (!carrito.metodosEnvio) carrito.metodosEnvio = []
		const toUpdate = carrito.metodosEnvio.findIndex((v) => v.idVendedor === vendedor)
		if (prefijo) carrito.metodosEnvio[toUpdate].prefTelefonoPerRecog = prefijo
		if (phone) carrito.metodosEnvio[toUpdate].telefonoPerRecog = phone
	},
	ACTIVAR_CDL_PLUS(active: boolean) {
		carrito.activarCdlPlus = active
		return '/carrito/actualizarMetodosEnvio'
	},
	ACTUALIZAR_METODO_PAGO(medioPago: MedioPagoClient) {
		const oldId = carrito.medioPago?.idTipoPago
		carrito.medioPago = medioPago
		if (oldId === carrito.medioPago.idTipoPago) return // solo llamar a actualizar si es cambio de tipoPago
		return '/carrito/actualizarFormaPago'
	},
	QUIERE_FACTURA(si_no: boolean) {
		if (carrito.datosFactura) {
			carrito.datosFactura.factura = si_no
		}
		return '/carrito/quiereFactura'
	},
	ACTUALIZAR_RGPD(rgpd?: RGPD) {
		if (!rgpd) carrito.tieneRgpd = false
		carrito.rgpd = rgpd
		if (carrito.registroUsuario && carrito.rgpd) {
			carrito.rgpd.email = carrito.registroUsuario.email
		}
	},
	ACTUALIZAR_RGPD_SOCIO(rgpd?: RGPD) {
		if (rgpd) {
			carrito.rgpdSocio = rgpd
			if (!carrito.rgpdSocio.email && carrito.registroUsuario) {
				carrito.rgpdSocio.email = carrito.registroUsuario.email
			}
		} else {
			carrito.rgpdSocio = undefined
		}
	},
	NUEVA_DIRECCION_FACTURA(invoiceData: EditableDatosFactura, address: DireccionEnvioNueva) {
		// @ts-ignore
		this.MODIFICAR_DIRECCION_FACTURA(invoiceData, address)
		return '/carrito/nuevaDireccionFacturacion'
	},
	NUEVA_DIRECCION_FACTURA_ANONIMO(invoiceData: EditableDatosFactura, address: DireccionEnvioNueva) {
		// @ts-ignore
		this.MODIFICAR_DIRECCION_FACTURA(invoiceData, address)
		return '/carrito/cambiarCodigoPostalFacturacion'
	},
	MODIFICAR_DIRECCION_FACTURA(invoiceData: EditableDatosFactura, address: DireccionEnvio) {
		carrito.informacionFacturacion = address
		if (carrito.datosFactura) {
			carrito.datosFactura.empresa = invoiceData.empresa
			carrito.datosFactura.documento = invoiceData.documento
			carrito.datosFactura.idTipoDocumentacion = invoiceData.idTipoDocumentacion
		}
		return '/carrito/modificaDireccionFacturacion'
	},
	APLICAR_OFERTA(code: string) {
		carrito.codigoOferta = code
		return '/carrito/aplicarOferta'
	},
	BORRAR_OFERTA() {
		return '/carrito/borrarOferta'
	},
	USUARIO_SIN_NOMBRE_APELLIDOS(usuarioNoIdentificado: UsuarioNoIdentificado) {
		carrito.usuarioNoIdentificado = usuarioNoIdentificado
	}
}

type EditableDatosFactura = Pick<DatosFactura, 'documento' | 'empresa' | 'idTipoDocumentacion'>
type UpdateEndpoint<T> = keyof typeof CART_SERVICES & T
type UpdateParams<T extends keyof typeof CART_SERVICES> = Parameters<(typeof CART_SERVICES)[T]>
export type ErrorCompra = {
	erroresValidacion: ErroresValidacion
}
export type ErrorCompraV2 = {
	errorCode: number
	message: string
	statusCode: number
}
export async function updateCart<T extends keyof typeof CART_SERVICES>(
	endpoint: UpdateEndpoint<T>,
	...rest: UpdateParams<T>
) {
	if (Cookies.get('ccrt') || Cookies.get('ccrtv')) {
		try {
			// @ts-ignore
			const selectedUrl = CART_SERVICES[endpoint](...rest) as `/${string}`
			if (selectedUrl) {
				const url = createUrl(selectedUrl, DEFAULT_QUERY_PARAMS)
				const compra = await http.post<DatosCompra | ErrorCompra | ErrorCompraV2>(url, carrito)
				if ('erroresValidacion' in compra) {
					handleValidationErrors(compra.erroresValidacion)
				} else if ('errorCode' in compra) {
					CNotifier.error({ title: compra.message, timeout: 1000 * 10 })
				} else {
					updateCartValues(compra)
					datosCompra.set(compra)
					window.dispatchEvent(new CustomEvent('cart-updated', { detail: compra }))
					if (compra.mensajesMostrar) {
						compra.mensajesMostrar.forEach((msg) => {
							CNotifier.error({ title: msg, timeout: 1000 * 10 })
						})
					}
				}
			}
		} catch (error) {
			CNotifier.error('Ha ocurrido un error')
		}
	}
}

export async function fetchPickUpPoints(codigopostal: string, vendedor: number) {
	const url = createUrl('/carrito/puntosRecogidaPorCodigoPostal', {
		...DEFAULT_QUERY_PARAMS,
		codigopostal,
		vendedor
	})
	const res = await http.get<PuntoRecogida[]>(url)
	return res
}
export async function fetchParcelPoints(codigopostal: string) {
	const url = createUrl('/carrito/getParcelPoints', { ...DEFAULT_QUERY_PARAMS, cpodireccion: codigopostal })
	const res = await http.get<PuntoEntrega[]>(url)
	return res
}
export async function finishOrder() {
	try {
		// si es klarna has lo de klarna si no es lo de klarna que no lo haga y asi la vida continua
		if (carrito.medioPago?.idTipoPago === 71) {
			carrito.medioPago.tokenAuthorization = await autorizeKlarnaPayment(carrito)
		}
		if (carrito.rgpd) await updateCart('ACTUALIZAR_RGPD', carrito.rgpd)
		if (carrito.registrarSocio && carrito.rgpdSocio) {
			await updateCart('ACTUALIZAR_RGPD_SOCIO', carrito.rgpdSocio)
		} else {
			await updateCart('ACTUALIZAR_RGPD_SOCIO')
		}
		const url = createUrl('/carrito/finalizarCompra', DEFAULT_QUERY_PARAMS)
		const res = await http.post<ConfirmacionCompra>(url, carrito)
		if (res.datosSesion) {
			await authenticate()
		}
		if (res.listaPedido) {
			datosCompraConfirmada.set(res)
			toRoute('/checkout/ok')
		}
		if (res.urlRedireccionPasarela) return (location.href = res.urlRedireccionPasarela)
		if (!res.ok && !res.listaErrores) throw new Error('Ocurrió un error en la compra')
		if (res.listaErrores) {
			const refetchCart = debounce(fetchCart, 20)
			res.listaErrores.forEach((err) => {
				const [code, msg] = err.split('|')
				CNotifier.error({ title: msg, timeout: 10000 }) // 10 segundos
				if (code === '29' || code === '16' || code === '23') {
					// codigo 29 se ha quitado un ebook por que ya lo tenia, en ese caso actualiza la cesta
					refetchCart()
				}
			})
		}
	} catch (error) {
		console.log(error)
		CNotifier.error('Ocurrió un error tratando de finalizar la compra. Inténtelo de nuevo')
	}
}
export async function confirmPaycomet(querie: ParsedUrlQuery) {
	try {
		const paycometParams = toStringQueries(querie)
		const url = createUrl('/carrito/confirmarPagoPaycomet', { ...DEFAULT_QUERY_PARAMS, ...paycometParams })
		const res = await http.post<ConfirmacionCompra>(url)
		if (res.ok) {
			datosCompraConfirmada.set(res)
			toRoute('/checkout/ok')
		} else if (res.listaErrores) {
			toRoute('/checkout/ko')
		}
	} catch (error) {
		console.log(error)
	}
}

export async function confirmPayPal(querie: ParsedUrlQuery) {
	try {
		const payPalParams = toStringQueries(querie)
		const url = createUrl(`/carrito/confirmarPagoPayPal`, { ...DEFAULT_QUERY_PARAMS, ...payPalParams })
		const res = await http.post<ConfirmacionCompra>(url)
		if (res.ok) {
			datosCompraConfirmada.set(res)
			toRoute('/checkout/ok')
		} else if (res.listaErrores) {
			toRoute('/checkout/ko')
		}
	} catch (error) {
		console.log(error)
	}
}

type SaldoResponse = {
	codigo: number
	movimientos: Array<{
		fecha: string
		importe: number
		tipoMovimiento: string
	}>
	saldo: number
	mensaje?: string
}

export async function checkBalance(numTarjeta: string, pinTarjeta = '0', idTipoPago: number) {
	const url = createUrl('/carrito/saldoMovimienotTarjeta', {
		...DEFAULT_QUERY_PARAMS,
		numTarjeta,
		idTipoPago,
		pinTarjeta
	})
	const res = await http.get<SaldoResponse>(url)
	return res
}

function createCartValues(compra: DatosCompra): DatosCompraClient {
	return {
		compraDigital: compra.compraDigital,
		compraEmpleado: compra.compraEmpleado,
		activarCdlPlus: false,
		activarCompra1paso: false,
		codigoOferta: '',
		desagruparPedido: compra.metodosEnvio?.listaMetodosEnvioPorVendedor[0].desagrupado || false,
		distintaDireccionEnvioFacturacion: false,
		envolverParaRegalo: false,
		informacionEnvio: findSelectedItem(compra.listaDireccionesEnvio),
		informacionFacturacion: findSelectedItem(compra.listaDireccionesFactura),
		metodosEnvio: findSelectedShippingMethods(compra.metodosEnvio),
		medioPago: findSelectedPaymentMethod(compra.metodosPago),
		datosFactura: findInvoiceData(compra.datosFacturaCompra),
		rgpd: {
			check1Rgpd: false,
			check2Rgpd: false,
			email: '',
			idClausula: 0,
			idClausulaPie: 0,
			ipAddress: '',
			origen: ''
		}
	}
}

function updateCartValues(compra: DatosCompra) {
	carrito = {
		...carrito,
		informacionEnvio: findSelectedItem(compra.listaDireccionesEnvio),
		informacionFacturacion: findSelectedItem(compra.listaDireccionesFactura),
		metodosEnvio: findSelectedShippingMethods(compra.metodosEnvio),
		datosFactura: findInvoiceData(compra.datosFacturaCompra)
	}
	if (!carrito.medioPago?.paytpvToken) {
		carrito.medioPago = findSelectedPaymentMethod(compra.metodosPago)
	}
}

function findSelectedItem<T>(items?: Array<T & { seleccionado: boolean }>) {
	if (!items) return
	if (items.length === 1) return items[0]
	return items.find((v) => v.seleccionado)
}

function findInvoiceData(invoice: DatosFacturaCompra) {
	return {
		documento: invoice.nif || '',
		factura: invoice.marcadoQuiereFactura || false,
		empresa: invoice.empresa || '',
		idTipoDocumentacion: +invoice.tipoDocumento || 1,
		facturaObligatoriaAnterior: invoice.facturaObligatoria,
		facturaObligatoria: invoice.facturaObligatoria,
		codigoAS400TipoDocumentacion: invoice.codigoAS400TipoDocumentacion || '',
		intracomunitario: invoice.permiteIntracomunitario
	}
}

function findSelectedShippingMethods(methods?: MetodosEnvio): MetodosEnvioClient[] {
	if (!methods) return []
	const result: MetodosEnvioClient[] = []
	methods.listaMetodosEnvioPorVendedor.forEach((envioPorVendedor) => {
		envioPorVendedor.listaMetodosEnvioPedido.forEach((envioPedido) => {
			const idVendedor = envioPedido.idVendedor
			let formaEnvio: keyof typeof envioPedido.mapaFormasEnvio
			for (formaEnvio in envioPedido.mapaFormasEnvio) {
				const forma = envioPedido.mapaFormasEnvio[formaEnvio]!
				const selected = forma.find((v) => v.seleccionado) as
					| FormaEnvioDomicilio
					| FormaEnvioRecogida
					| FormaEnvioEbook
				if (selected) {
					const tSelected: MetodosEnvioClient = {
						idCondicionEnvio: selected.idCondicionEnvio,
						idMetodoEnvio: selected.idMetodoEnvio,
						idVendedor: idVendedor
					}
					if ('listaPuntosRecogida' in selected) {
						const puntoRecogida = selected.listaPuntosRecogida?.find((p) => p.seleccionado)
						if (puntoRecogida) {
							tSelected.idPuntoRecogida = puntoRecogida.idPuntoRecogida
							tSelected.telefonoPerRecog = selected.telefonoPerRecog
							tSelected.datosPuntoRecogida = puntoRecogida
						}
					}
					result.push(tSelected)
				}
			}
		})
	})
	return result
}

function findSelectedPaymentMethod(methods: MetodosPago): MedioPagoClient {
	let selected = findSelectedItem(methods.listaMetodosPago)
	if (!selected) selected = methods.listaMetodosPago[0]
	const result: MedioPagoClient = {
		idTipoPago: selected.idTipoPago
	}
	if (selected.listaTarjetasUsuario) {
		const selectedCard = selected.listaTarjetasUsuario.find((card) => card.selected)
		if (selectedCard) {
			if (selected.idTipoPago === 35 || selected.idTipoPago === 69) {
				result.tarjetaComplementaria = {
					idFormaPago: selectedCard.id
				}
			} else {
				result.idFormaPago = selectedCard.id
			}
		}
	}
	return result
}

export async function addProductPasarela(ids: number[]) {
	try {
		const url = createUrl('/pasarela/pasarelaCompra', { ...DEFAULT_QUERY_PARAMS, idsProductos: ids })
		await http.get(url)
		await fetchCart()
		Panel.open('PanelBasket')
	} catch (error) {
		CNotifier.error('No se pudo añadir el producto a la compra')
	}
}

type KlarnaSession = {
	clientToken: string
	paymentMethodCategories: Array<{
		assetUrls: {
			descriptive: string
			standard: string
		}
		identifier: string
		name: string
	}>
	sessionId: string
}
let klarnaSessionId = ''
export async function createKlarnaSession() {
	const compra = get(datosCompra)
	const url = createUrl('/carrito/createSessionKlarna', DEFAULT_QUERY_PARAMS)
	const res = await http.post<KlarnaSession>(url, {
		mapaImportesPorMoneda: compra?.contenidoPedido.mapaImportesPorMoneda,
		mapaPedidosPorMoneda: compra?.contenidoPedido.mapaPedidosPorMoneda,
		intent: 'BUY'
	})
	klarnaSessionId = res.sessionId
	return res
}

export async function updateSessionKlarna() {
	const compra = get(datosCompra)
	const url = createUrl('/carrito/updateSessionKlarna', DEFAULT_QUERY_PARAMS)
	const res = await http.post<KlarnaSession>(url, {
		mapaImportesPorMoneda: compra?.contenidoPedido.mapaImportesPorMoneda,
		mapaPedidosPorMoneda: compra?.contenidoPedido.mapaPedidosPorMoneda,
		sessionToken: klarnaSessionId
	})
	return res
}

export const compraVirtualActiva = import.meta.env.SSR
	? writable(false)
	: writable(Boolean(sessionStorage.getItem('compraVirtualActiva')))
let stopCompraVirtual: ReturnType<typeof http.onRequest> | undefined
compraVirtualActiva.subscribe((activa) => {
	if (activa) {
		stopCompraVirtual = startCompraVirtual()
		window.addEventListener('beforeunload', stopCompraVirtual)
	} else {
		stopCompraVirtual && stopCompraVirtual()
	}
})
function startCompraVirtual() {
	const off = http.onRequest(({ req }) => {
		req.headers = { ...req.headers, comprav: '1' }
	})
	sessionStorage.setItem('compraVirtualActiva', 'activa')
	return () => {
		Cookies.remove('ccrtv')
		sessionStorage.removeItem('compraVirtualActiva')
		off()
		fetchCart()
	}
}

export async function confirmarErrorPaypal(querie: ParsedUrlQuery) {
	try {
		const paycometParams = toStringQueries(querie)
		const url = createUrl('/carrito/confirmarErrorPagoPayPal', { ...DEFAULT_QUERY_PARAMS, ...paycometParams })
		await http.post(url)
	} catch (error) {
		console.log(error)
	}
}
