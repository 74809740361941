import { CNotifier } from '@chasi/ui'
import { PAIS_CURRENCY_CODE, PAIS_LANG } from 'src/config'
import { writable, type Readable } from 'svelte/store'
import { stringify, parse } from 'devalue'

export function createUrl(url: string, queries: Record<string, any> = {}, removeQueries?: string[]) {
	const result = new URL(url, 'http://localhost:3000')
	if (removeQueries) {
		removeQueries.forEach((q) => {
			result.searchParams.delete(q)
		})
	}
	for (const key in queries) {
		if (queries[key] === undefined) continue
		if (result.searchParams.has(key)) result.searchParams.delete(key)
		result.searchParams.append(key, queries[key])
	}
	return result.pathname + result.search
}

export function authorsToString(authors?: { name: string }[]) {
	try {
		if (!authors || !authors.length) return ''
		const names = authors.map((a) => a.name)
		// @ts-ignore
		const formatter = new Intl.ListFormat('es', { style: 'short', type: 'conjunction' })
		return formatter.format(names)
	} catch (error) {
		return ''
	}
}

export function _if(cond: boolean | undefined, value: string) {
	return cond ? value : ''
}

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
	func: F,
	waitFor: number
): (...args: Parameters<F>) => void {
	let timeout: NodeJS.Timeout
	return (...args: Parameters<F>): void => {
		clearTimeout(timeout)
		timeout = setTimeout(() => func(...args), waitFor)
	}
}

const CURRENCY_FORMATER = {
	EUR: new Intl.NumberFormat('es-ES', {
		style: 'currency',
		currency: 'EUR'
	}),
	MXN: new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN'
	}),
	COP: new Intl.NumberFormat('es-CO', {
		style: 'currency',
		currency: 'COP',
		maximumFractionDigits: 0
	})
} as const

export function formatPrice(value: number | string, forceCurrency?: keyof typeof CURRENCY_FORMATER) {
	const asNumber = +value
	if (!asNumber) return ''
	if (forceCurrency) return CURRENCY_FORMATER[forceCurrency].format(asNumber)
	return CURRENCY_FORMATER[PAIS_CURRENCY_CODE].format(asNumber)
}

export function pluralize(text: string, num: number) {
	return num === 1 ? text : text + 's'
}

export function findInObj<T = any>(obj: any, key: string, value: any): T | undefined {
	try {
		if (!obj) return
		JSON.stringify(obj, (_KEY, nestedValue) => {
			if (nestedValue && nestedValue[key] === value) throw nestedValue
			return nestedValue
		})
	} catch (result) {
		return result as T
	}
}

export function randomStr() {
	return Math.random().toString(36).substring(2, 9)
}

export const listFormatter = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' })

export function pathToName(path: string) {
	const match = path.match(/[ \w-]+?(?=\.)/)
	return match ? match[0] : path
}

export function strimHTML(text: string) {
	return text.replace(/(<([^>]+)>)/gi, '')
}

export function handleValidationErrors(errs: ErroresValidacion) {
	Object.values(errs).forEach((messages) => {
		messages.forEach((msg) => {
			CNotifier.error(msg.message)
		})
	})
}

export function toDate(str: string) {
	// str fromat => DD/MM/YYYY
	const p = str.split('/')
	// YYYY-MM-DD
	return new Date(`${p[2]}-${p[1]}-${p[0]}`)
}

export function prettifySchedule(store: PuntoRecogida | PuntoEntrega): string {
	if ('horario' in store) {
		return store.horario.replace(', en horario de ', '')
	} else {
		const reduced: string[] = []
		let oldSchedule = ''
		let oldDay = ''
		store.horarios.forEach((h, i) => {
			const day = h.substring(0, h.indexOf(':'))
			const schedule = h.substring(h.indexOf(':') + 1)
			if (schedule !== oldSchedule) {
				reduced.push(`${day} - ${schedule}`)
				oldSchedule = schedule
				oldDay = day
			} else {
				reduced[reduced.length - 1] = `${oldDay} a ${day} - ${schedule}`
			}
		})
		return reduced.join('\n')
	}
}

export function waitForDefined<T>(
	getter: () => T,
	callback: (value: NonNullable<T>) => void,
	checkInterval = 100,
	maxAttempts = 50
): void {
	let attempts = 0
	const interval = setInterval(() => {
		attempts++
		const value = getter()
		if (value) {
			clearInterval(interval)
			callback(value)
		} else if (attempts >= maxAttempts) {
			clearInterval(interval)
		}
	}, checkInterval)
}

export function volverAtras(delta = 1) {
	if (import.meta.env.SSR) return

	const referrer = document.referrer
	const debeVolverAtras = referrer && new URL(referrer).hostname === location.hostname
	debeVolverAtras ? history.go(delta) : (location.href = '/')
}

function isObject(item: any): item is object {
	return item && typeof item === 'object' && !Array.isArray(item)
}

export function deepMerge<T extends Record<string, any>>(obj1: T, obj2: T) {
	if (typeof obj1 !== typeof obj2)
		throw new Error('Both parameters should be of the same type, either objects or arrays.')

	const result = { ...obj1, ...obj2 }
	for (const key in result) {
		if (isObject(obj1[key]) && isObject(obj2[key])) {
			result[key] = deepMerge(obj1[key], obj2[key])
		} else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
			// @ts-ignore
			result[key] = mergeArrayItems(obj1[key], obj2[key])
		}
	}
	return result
}

export function mergeArrayItems<T extends Record<string, any>>(source: T[] = [], target: T[] = []) {
	const mergedArray: T[] = []

	for (let i = 0; i < target.length; i++) {
		const item1 = source[i] || {}
		const item2 = target[i] || {}
		mergedArray.push(deepMerge(item1, item2))
	}

	return mergedArray
}

export function extractEans(txt: string) {
	const regex = /\b(\d{9,10}X?|\d{13})\b/g
	const eans = txt.match(regex)
	return eans ? eans : []
}

export function formatContributorName(name: string, formater = (v: string) => v) {
	if (!name) return ''
	const [apellido, nombre] = name.split(',')
	return formater(`${nombre || ''} ${apellido || ''}`)
}

export function createAssertionBlock<P, T extends P[]>(
	callback: (assert: typeof assertblock, ...args: T) => void
) {
	return (...args: T) => {
		try {
			callback(assertblock, ...args)
		} catch (error) {
			if (error instanceof Error) {
				console.log(error)
			}
		}
	}
}

function assertblock<T>(val: T, callback: (val: NonNullable<T>) => void): void {
	if (val) {
		callback && callback(val)
		throw 'stop'
	}
}

export function persistentWritable<T>(name: string, initialValue: T) {
	if (import.meta.env.SSR) throw new Error('persistentWritable can only be called on client side')
	const persistedValue = localStorage.getItem(name)
	const value = persistedValue ? (parse(persistedValue) as T) : initialValue
	const store = writable(value)
	store.subscribe((v) => {
		localStorage.setItem(name, stringify(v))
	})
	return store
}

export function sleep(ms = 1000) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export function watchStore<T>(store: Readable<T>, callback: (current: T, old: T) => void) {
	let old: T
	let started = false
	return store.subscribe((current) => {
		if (started) callback(current, old)
		old = structuredClone(current)
		started = true
	})
}

/** que hay en el primer array que no hay en el segundo */
export function getDiffrence<T extends Record<string, any>, K extends keyof T>(
	array1: T[],
	array2: T[],
	identifier: K & Primitive
) {
	const set = new Set(array2.map((item) => item[identifier]))
	return array1.filter((item) => !set.has(item[identifier]))
}

export function toStringQueries(query: ParsedUrlQuery) {
	const res: Record<string, string> = {}
	for (const key in query) {
		if (!query[key]) continue
		res[key] = typeof query[key] === 'string' ? query[key] : query[key][0]
	}
	return res
}

export function fixedEncodeURIComponent(str: string) {
	return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
		return '%' + c.charCodeAt(0).toString(16)
	})
}
