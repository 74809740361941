import type { SvelteComponent } from 'svelte'
import { createGlobalComponentApi } from '../mountComponent'
import type { NormalizedProduct } from 'src/services/product'

type AviablePanels = {
	PanelBasket: undefined
	PanelAltaSocio: undefined
	PanelUpdatePhone: undefined
	PanelFavoriteStore: undefined
	PanelFastBuy: { title?: string }
	PanelRegaloProducto: undefined
	PanelDisponibilidadTienda: { product: NormalizedProduct }
	PanelFormatos: undefined
	FollowAuthor: { authorid: number; title?: string }
	FollowSaga: { idSubcoleccion: string; title?: string }
	PanelLibrosDeTexto: { idProductos: string; label?: string }
}

const panelComponents = import.meta.glob<{ default: typeof SvelteComponent }>('./*.svelte')

export const Panel = createGlobalComponentApi<AviablePanels>(panelComponents)
