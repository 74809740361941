type RequestInterceptor = (params: { path: string; req: RequestInit; requestData?: unknown }) => void
type ResponseInterceptor = (params: { path: string; requestData?: unknown; responseData?: unknown }) => void
export class NativeFetchWrapper {
	#prefix: string
	#globalOptions: RequestInit
	#reqInterceptors: Set<RequestInterceptor>
	#resInterceptors: Set<ResponseInterceptor>
	constructor(prefix?: string, globalOptions?: RequestInit) {
		this.#prefix = prefix || ''
		this.#globalOptions = globalOptions || {}
		this.#resInterceptors = new Set()
		this.#reqInterceptors = new Set()
	}

	setGlobalHeader(name: string, value: string) {
		this.#globalOptions.headers = {
			...this.#globalOptions.headers,
			[name]: value
		}
	}

	onRequest(callback: RequestInterceptor) {
		this.#reqInterceptors.add(callback)
		return () => {
			this.#reqInterceptors.delete(callback)
		}
	}

	onResponse(callback: ResponseInterceptor) {
		this.#resInterceptors.add(callback)
		return () => {
			this.#resInterceptors.delete(callback)
		}
	}

	get<T>(path: string, options?: RequestInit) {
		return this.#send<T>(path, { method: 'GET', ...options })
	}

	delete<T>(path: string, data?: any, options?: RequestInit) {
		return this.#send<T>(path, { method: 'DELETE', ...options }, data)
	}

	post<T>(path: string, data?: any, options?: RequestInit) {
		return this.#send<T>(path, { method: 'POST', ...options }, data)
	}

	put<T>(path: string, data?: any, options?: RequestInit) {
		return this.#send<T>(path, { method: 'PUT', ...options }, data)
	}

	#parseOptions(path: string, options?: RequestInit, data?: any) {
		const opt: RequestInit = {
			...this.#globalOptions,
			...options
		}
		if (data) opt.body = JSON.stringify(data)
		const url = path.startsWith('http') ? path : `${this.#prefix}${path}`
		return { url, opt }
	}

	async #send<T>(path: string, options?: RequestInit, data?: any): Promise<T> {
		const parsedOpts = this.#parseOptions(path, options, data)
		for (let callback of this.#reqInterceptors) {
			callback({ path, req: parsedOpts.opt, requestData: data })
		}
		const res = await fetch(parsedOpts.url, parsedOpts.opt)
		if (!res.ok) return Promise.reject(res)
		const json = await res.json().catch(() => undefined)
		setTimeout(() => {
			for (let callback of this.#resInterceptors) {
				callback({ path, requestData: data, responseData: json })
			}
		}, 1)
		return json
	}

	async raw(path: string, options?: RequestInit, data?: any): Promise<Response> {
		const parsedOpts = this.#parseOptions(path, options, data)
		const res = await fetch(parsedOpts.url, parsedOpts.opt)
		return res
	}
}
